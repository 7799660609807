.slider {
  padding: 2rem;
  width: 100%;
  height: 100%;
}
.slider .swiper-container1 {
  width: 100%;
  height: 100%;
}
.slider .swiper-container2 {
  width: 100%;
  height: 100%;
}
.slider__flex {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}
.slider__col {
  display: flex;
  flex-direction: column;
  width: 4rem;
  min-height: 4rem;
  margin-right: 32px;
}
.slider__prev,
.slider__next {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slider__prev:focus,
.slider__next:focus {
  outline: none;
}
.slider__prev.swiper-button-disabled,
.slider__next.swiper-button-disabled {
  opacity: 0.5;
}
.slider__thumbs {
  height: calc(400px - 96px);
  cursor: pointer;
}
/* ********* */
.slider__thumbs .slider__image {
  transition: 0.25s;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
  display: flex;
  justify-content: center;
}
.slider__thumbs .slider__image img {
  width: 100%;
  max-width: 3.2rem;
  max-height: 3.2rem;
  height: auto;
  object-fit: cover;
}
/* ********* */
.slider__thumbs .slider__image:hover {
  opacity: 1;
}
.slider__thumbs .swiper-slide-thumb-active .slider__image {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}
.slider__images {
  height: 92vh;
  width: 80%;
  cursor: grab;
}
/* .slider__images .slider__image img {
  transition: 3s;
}
.slider__images .slider__image:hover img {
  transform: scale(1.1);
} */
.slider__image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.slider__image img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
@media (max-width: 767.98px) {
  .slider__flex {
    flex-direction: column;
    justify-content: center;
  }
  .slider__col {
    flex-direction: row;
    align-items: center;
    margin-right: 0;
    margin-top: 2.5rem;
    width: 100%;
  }
  .slider__images {
    margin-top: 1rem;
    width: 100%;
    height: auto;
  }

  .slider__thumbs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - 96px);
    margin: 0 1rem 1rem;
  }

  .slider__thumbs .slider__image img {
    width: 100%;
    max-width: 3.2rem;
    min-height: 2.5rem;
    max-height: 2.5rem;
    object-fit: cover;
  }
  .slider__prev,
  .slider__next {
    height: auto;
    width: 32px;
    margin-bottom: 0.75rem;
  }
  .slider__prev {
    transform: rotate(-90deg);
  }
  .slider__next {
    transform: rotate(-90deg);
  }
}
