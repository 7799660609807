:root {
  --menu-pad: 2rem;
  --menu-gap: 1rem;
  --menu-marg-dir: 2rem;
  --menu-ativo: 2rem;
  --menu-img: 4rem;
}

.menu {
  position: absolute;
  width: 100%;
  padding: var(--menu-pad);
  display: flex;
  gap: 1rem;
  z-index: 109;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.menu .bloco_1,
.menu .bloco_2 {
  display: flex;
}

.menu .bloco_1 {
  width: 50%;
}

.menu .bloco_2 {
  justify-content: space-between;
  width: 50%;
}

.menu .btn_home {
  margin-right: var(--menu-marg-dir);
}

.menu .btn_tela_cheia,
.menu .btn_home,
.menu .btn_circulos,
.menu .btn_seta {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu img {
  max-width: var(--menu-img);
}

/* .menu .btn_home img,
.menu .btn_telha_cheia img {
  max-width: calc(var(--menu-img) * 0.8);
} */

.posicao_home {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.posicao_home span {
  width: 0.5rem;
  height: 0.5rem;
  background-color: rgba(37, 150, 190, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.pag_ativa img {
  width: var(--menu-ativo);
  margin: 0 0.5rem;
}

.pag_ativa_H img {
  transition: width 0.5s ease-in-out;
  margin: 0 0.5rem;
}

/* animação */

.pulsar {
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes pulsar {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes pulsar {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* responsivo */

@media only screen and (max-width: 1367px) {
  :root {
    --menu-ativo: 2rem;
    --menu-img: 3rem;
  }
}

@media only screen and (max-width: 1025px) {
  .menu {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.5);
    bottom: 5px;
    display: flex;
    z-index: 109;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    z-index: 300;
    width: 100vw;
  }
  .menu .btn_home {
    margin-right: 0;
  }
  .btn_tela_cheia {
    display: none;
  }
  .menu .bloco_1 {
    width: 100%;
    justify-content: space-around;
  }
  .pag_ativa img {
    width: var(--menu-ativo);
    margin: 0 0.5rem;
  }
  .menu .btn_circulos {
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .seta_home_mob {
    position: absolute;
    right: 2%;
    top: 2%;
    z-index: 500;
    display: flex;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
  }
  .seta_home_mob img {
    filter: contrast(4);
  }
  .video_1 {
    margin-bottom: 2.5rem;
  }
}
