.modal_container {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.btn_fechar {
  position: absolute;
  font-size: 2rem;
  padding: 0.5rem;
  z-index: 1001;
  color: #fff;
  background-color: transparent;
  border: none;
  right: 1rem;
  top: 1rem;
  text-align: right;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .modal_container {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1000;
  }
}
