:root {
  --video-width: 100vw;
  --video-height: 100vh;
}

.intro_video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  z-index: 10000000000;
}

.modal_video,
.modal_video_2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.modal_video iframe,
.modal_video_2 iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--video-width);
  height: var(--video-height);
  transform: translate(-50%, -50%);
}

@media (min-aspect-ratio: 16/9) {
  :root {
    --video-height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  :root {
    --video-width: 177.78vh;
  }
}

@media only screen and (max-width: 1025px) {
  .modal_video iframe {
    height: 56.25vw;
  }

  .modal_video_2 iframe {
    height: 56.25vw;
  }
}
