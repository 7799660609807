:root {
  --top-camada: -10%;
}

.camada {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: var(--top-camada);
}

.camada .ativa {
  top: 0;
}

.camada img:not(:first-of-type) {
  width: 100%;
  height: auto;
  min-height: 100vh;
  object-fit: cover;
}

.conteudo {
  width: 100vw;
  height: 100vh;
  z-index: 108;
  position: relative;
}

.conteudo p,
.conteudo h2 {
  color: gray;
}

.conteudo b {
  font-weight: bold;
}

.conteudo .home1 p span {
  font-size: 3rem;
}

.conteudo .home2,
.conteudo .home3,
.conteudo .home4,
.conteudo .home5,
.conteudo .home6 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.conteudo .home2 .container {
  position: absolute;
  top: 20%;
  right: 10%;
  max-width: 30%;
}

.conteudo .home3 .container {
  position: absolute;
  top: 20%;
  right: 30%;
  max-width: 30%;
}

.conteudo .home4 .container {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  max-width: max-content;
}

.conteudo .home5 .container {
  position: absolute;
  top: 20%;
  right: 20%;
  transform: translateY(-20%, -20%);
  max-width: max-content;
}

.conteudo .home6 .container {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  max-width: max-content;
}

.conteudo .home2 h2,
.conteudo .home3 h2 {
  text-align: center;
  line-height: 1;
  margin-bottom: 1rem;
}

.conteudo .home2 p,
.conteudo .home3 p {
  padding: 0 1.5rem;
  max-height: 25vh;
  overflow-y: auto;
}

.conteudo .home6 .container img:hover,
.conteudo.home7 .container .video_1 img:hover,
.conteudo.home7 .container .video_2 img:hover {
  transform: scale(1.025);
  transition: transform 0.5s ease-in-out;
  opacity: 0.75;
  cursor: pointer;
}

.conteudo .home6 .container {
  display: flex;
  flex-direction: row;
}

.conteudo .home6 h2 {
  padding-top: 10%;
}

.home1 .camada_6 {
  top: -5%;
  left: -1%;
}

/* home7 */

.conteudo.home7 {
  width: 100%;
  height: 100%;
}

.conteudo.home7 .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85vh;
}

.conteudo.home7 .container .titulo,
.conteudo.home7 .container .video {
  width: 100%;
  display: flex;
  justify-content: center;
}

.conteudo.home7 .container .video_1 {
  margin-right: 2rem;
}

.conteudo.home7 .container .titulo {
  margin-bottom: 2rem;
}

.conteudo.home7 .container .video_1,
.conteudo.home7 .container .video_2 {
  max-width: 30%;
  cursor: pointer;
}

.conteudo.home7 .container .modal_video {
  width: 80vw;
  height: 80vh;
}

.conteudo.home7 .container .modal_video iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--video-width);
  height: var(--video-height);
  transform: translate(-50%, -50%);
}

.modal_home7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:fullscreen .camada {
  top: 0;
}

/* mob */

.mobile {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
}
.topo_mob {
  display: grid;
  grid-template-columns: 1fr;
}

.topo_mob .camada_mob {
  grid-row-start: 1;
  grid-column-start: 1;
}

.conteudo_mob {
  display: flex;
  justify-content: center;
  z-index: 200;
  padding: 0 15% 5% 15%;
}

.conteudo_mob .video h4 {
  color: gray;
  text-align: center;
}

.rodape_mob {
  z-index: 99;
}

/* .rodape_mob .camada_mob:nth-last-of-type() {
  background-color: yellow;
} */

@media (max-width: 1601px) {
}

@media (max-width: 1441px) {
  :root {
    --top-camada: -15%;
  }
  .livro {
    max-height: 300px;
  }
}

@media (max-width: 1281px) {
}

@media (max-width: 1025px) {
  .site_container {
    top: 0;
  }
}

@media (max-width: 768px) {
  h2,
  .slider_frase h3 {
    margin: 1rem 0 2rem 0;
  }
}
