@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@font-face {
  font-family: "Moneta";
  src: url("/public/font/moneta.woff") format("woff");
}

:root {
  --font-2: "Philosopher", sans-serif;
  --titulo-cor: #737373;
  --titulo-tamanho: 4rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  border: none;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  line-height: 1.5;
  font-weight: 400;
  background-color: white;
  border: none;
  color: rgb(61, 61, 61);
}

h2,
.slider_frase h3 {
  font-family: var(--font-2);
  font-size: var(--titulo-tamanho);
  font-weight: 400;
  text-align: center;
  color: var(--titulo-cor);
  line-height: 1;
}

h3,
h4,
h5,
h6 {
  font-family: var(--font-2);
  text-align: center;
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

ul {
  list-style: none;
}

@media only screen and (max-width: 1367px) {
  :root {
    --titulo-tamanho: 2.5rem;
  }
}
