:root {
  --cor-texto-ttip: rgb(41, 41, 41);
  --fonte-tamanho-ttip: 2.5rem;
  --pos-esq-ttip: 32rem;
  --pos-top-ttip: 2.5rem;
}

.tooltip {
  font-family: var(--font-2);
  font-weight: 300;
  position: absolute;
  font-size: var(--fonte-tamanho-ttip);
  color: var(--cor-texto-ttip);
  left: var(--pos-esq-ttip);
  top: var(--pos-top-ttip);
  z-index: 100;
}

@media only screen and (max-width: 1367px) {
  :root {
    --fonte-tamanho-ttip: 1.7rem;
    --pos-esq-ttip: 26rem;
    --pos-top-ttip: 2.5rem;
  }
}

@media only screen and (max-width: 1025px) {
  :root {
    --fonte-tamanho-ttip: 1.7rem;
    --pos-esq-ttip: 26rem;
    --pos-top-ttip: 2.5rem;
  }
  .tooltip {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  :root {
    --fonte-tamanho-ttip: 1.7rem;
    --pos-esq-ttip: 50%;
    --pos-top-ttip: -50%;
  }
}
