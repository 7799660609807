:root {
  --titulo-tamanho-pags: 2rem;
  --titulo-tamanho-home: 3rem;
}

/* Template */

.template h2:hover {
  transform: scale(1.025);
  transition: transform 0.2s ease-in-out;
}

.conteudo h2,
.conteudo_pag_mob h2 {
  color: gray;
  font-size: var(--titulo-tamanho-pags);
  cursor: pointer;
  text-decoration: underline;
}
.conteudo h2:hover,
.conteudo_pag_mob h2:hover {
  opacity: 0.5;
}

.conteudo .container h2 {
  text-decoration: none;
  cursor: default;
  font-size: var(--titulo-tamanho-home);
}

.conteudo .container h2:hover {
  opacity: 1;
}

.o_mar_4.titulo_4,
.o_mar_5.titulo_5,
.o_mar_6.titulo_6,
.natureza_5.titulo_5,
.natureza_6.titulo_6,
.pessoas_5.titulo_5,
.usinas_5.titulo_5,
.mario_4.titulo_4 {
  top: 22.5%;
  position: absolute;
  z-index: 110;
}

/* Mario por Mario */

.mario_4.titulo_4 {
  top: 40%;
  right: 30%;
}

/* Natureza */

.natureza_5.titulo_5 {
  left: 40%;
  top: 35%;
}

.natureza_6.titulo_6 {
  right: 38%;
  top: 45%;
}

/* O mar */

.o_mar_4.titulo_4 {
  left: 41.5%;
}

.o_mar_5.titulo_5 {
  right: 31%;
  top: 30%;
}

.o_mar_6.titulo_6 {
  top: 50%;
  left: 55%;
  transform: translate(-50%, -55%);
}

/* Usinas */

.usinas_5.titulo_5 {
  right: 20%;
  top: 32.5%;
}

/* Pessoas */

.pessoas_5.titulo_5 {
  right: 20%;
  top: 12.5%;
}

/* Expediente */

.container_exp {
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container_exp .titulo {
  margin-bottom: 2rem;
}

.container_exp .expediente {
  max-width: 50%;
}

.container_exp .expediente .producao {
  margin-top: 1.5rem;
}

.container_exp .expediente .bloco_baixo {
  display: flex;
}

.container_exp .equipe,
.container_exp .producao {
  width: 50%;
}

.container_exp .producao {
  width: 80%;
}

.container_exp .expediente .producao ul {
  background-color: red;
}

.container_exp .equipe {
  width: 75%;
}

.expediente .outros {
  display: flex;
  flex-direction: column;
}

.expediente .outros:not(last-child) > * {
  margin-bottom: 2rem;
}

.expediente .logos {
  display: flex;
  align-items: center;
}
.expediente .logos div {
  display: flex;
}

.expediente .logo_1,
.expediente .logo_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: gray;
}
.expediente img {
  max-width: 200px;
}

.expediente .apoio {
  display: flex;
  justify-content: center;
}

/* Animação */

@keyframes pulse {
  0% {
    transform: scale(0.992);
    opacity: 0.9;
  }

  70% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.992);
    opacity: 0.9;
  }
}

/* .camada_5.pessoas_5 {
  transform: scale(1);
  animation: pulse 2s infinite;
} */

/* mobile */

.img_pag_mob {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img_pag_mob:hover {
  transform: scale(1.025);
  transition: transform 0.2s ease-in-out;
}

.titulo_pag_mob {
  position: absolute;
}

.conteudo_pag_mob h2 {
  font-size: 1.5rem;
}

.conteudo_pag_mob .subcont_mob:nth-of-type(odd) .img_pag_mob {
  align-items: flex-end;
}

.conteudo_pag_mob .subcont_mob:nth-of-type(even) .img_pag_mob {
  align-items: flex-start;
}

/* natureza mob */

.conteudo_pag_mob .natureza_mob:nth-of-type(1) .titulo_pag_mob:nth-of-type(1) {
  right: 15%;
}

.conteudo_pag_mob .natureza_mob:nth-of-type(2) .titulo_pag_mob:nth-of-type(1) {
  left: 10%;
}

/* o mar mob */

.conteudo_pag_mob .omar_mob:nth-of-type(1) .titulo_pag_mob:nth-of-type(1) {
  right: 15%;
  bottom: 13%;
}

.conteudo_pag_mob .omar_mob:nth-of-type(2) .titulo_pag_mob:nth-of-type(1) {
  left: 18%;
}

.conteudo_pag_mob .omar_mob:nth-of-type(3) .titulo_pag_mob:nth-of-type(1) {
  right: 8%;
  top: 7.5%;
}

/* pessoas */

.conteudo_pag_mob .pessoas_mob:nth-of-type(1) .titulo_pag_mob:nth-of-type(1) {
  right: 15%;
  top: 5%;
}

/* pessoas */

.conteudo_pag_mob .usinas_mob:nth-of-type(1) .titulo_pag_mob:nth-of-type(1) {
  right: 10%;
  top: 5%;
}

/* Mario por Mario */

.conteudo_pag_mob .mario_mob:nth-of-type(1) .titulo_pag_mob:nth-of-type(1) {
  left: 5%;
  top: 15%;
}

@media only screen and (max-width: 1367px) {
  :root {
    --titulo-tamanho-pags: 1.4rem;
    --titulo-tamanho-home: 2.5rem;
  }
  .container_exp {
    overflow-y: auto;
    font-size: 0.85rem;
    padding-top: 5rem;
    display: flex;
    justify-content: center;
  }

  .container_exp .equipe,
  .container_exp .producao {
    width: 100%;
  }
  .container_exp .titulo {
    margin-bottom: 0;
  }
  .container_exp .expediente .bloco_baixo {
    width: 65vw;
    margin-left: -10%;
  }
}

@media only screen and (max-width: 1025px) {
  /* Expediente */

  .container_exp {
    padding: 0 15%;
    font-size: 0.9rem;
  }

  .container_exp h3 {
    text-align: center;
    font-size: 1.25rem;
  }

  .container_exp .expediente {
    max-width: initial;
  }

  .container_exp .expediente .bloco_baixo {
    display: initial;
  }

  .container_exp .equipe,
  .container_exp .producao {
    width: 100%;
  }

  .container_exp .equipe {
    width: 100%;
  }

  .expediente .outros {
    display: flex;
    flex-direction: column;
  }

  .expediente .logos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .expediente .logos_patrocinio img:nth-child(2) {
    margin-top: 2rem;
  }
  .expediente .logo_2 {
    margin-top: 4rem;
  }
  .expediente img {
    max-width: 200px;
  }
  .expediente .logos_apoio,
  .expediente .logos_patrocinio {
    display: flex;
    flex-direction: column;
  }
}
